import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import IconList, { IconItem } from './embeddable/IconList'

const SectionNavigation = (props: { className?: string, details?: boolean }) => (
  <Container className={props.className}>
    <Row>
      <Col xs={12} sm={6} md={4}>
        <IconList title='Japanisch für Anfänger' center={true}>
          <IconItem to='/japanische-schrift/' icon='writing' details={props.details&&'Hiragana, Katakana und Aussprache.'}>Japanische Schrift</IconItem>
          <IconItem to='/japanische-schrift/kanji-lernen/' icon='kanji' details={props.details&&'Übersicht und Lerntipps.'}>Kanji</IconItem>
          <IconItem to='/japanische-grammatik-lernen/' icon='grammar' details={props.details&&'Für Anfänger einfach erklärt.'}>Japanische Grammatik</IconItem>
          <IconItem to='/japanisch-lernen-wie-anfangen/' icon='hints' details={props.details&&'Mach es dir nicht so schwer.'}>Tipps und Methoden</IconItem>
        </IconList>
      </Col>
      <Col xs={12} sm={6} md={4}>
        <IconList title='Buch & Trainer' center={true}>
          <IconItem to='/grammatikbuch/' icon='book' details={props.details&&'Das Buch als PDF zum runterladen.'}>Schrift & Grammatik</IconItem>
          <IconItem to='/japanische-schrift/hiragana-trainer/' icon='kanatrainer' details={props.details&&'Hiragana und Katakana lesen.'}>Kana-Trainer</IconItem>
          <IconItem to='/japanische-schrift/kanji-trainer/' icon='kanjitrainer' details={props.details&&'Kanji lesen und schreiben.'}>Kanji-Trainer</IconItem>
        </IconList>
      </Col>
      <Col xs={12} sm={6} md={4}>
        <IconList title='Listen' center={true}>
          <IconItem to='/japanische-vokabeln-lernen/' icon='vocabulary' details={props.details&&'Wichtige Vokabeln für Anfänger.'}>Vokabeln für Anfänger</IconItem>
          <IconItem to='/jlpt-material/' icon='jlpt' details={props.details&&'Vokabeln, Kanji, Grammatik.'}>JLPT-Listen</IconItem>
        </IconList>
      </Col>
    </Row>
  </Container>
);

export default SectionNavigation;