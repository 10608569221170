import { Link } from 'gatsby'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import IconList, { IconItem } from '../components/embeddable/IconList'
import SectionNav from '../components/SectionNav'

import styles from './Header.module.scss'
import logo from './logo-white.png'

const Header = (props: { logoOnly?: boolean }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const navToggled = (event: any) => {
    setVisible(!visible);
  }

  return (<div>
    <Navbar variant="light" className={styles.navbar}>
      <Container className={styles.headerStyle}>
        <div className={styles.menuHome}>
          <Link to={'/'}>
            <img src={logo} width={32} height={32} alt='Startseite'/>
            {!props.logoOnly && <span>Jakobs Japanisch</span>}
          </Link>
        </div>
        {!props.logoOnly && <div className={styles.menuToggle} onClick={navToggled}>
          <div className={styles.menuCaption}>Alle Themen</div>
          <div className={styles.hamburger}>
            <i className={styles.hamburgerIcon + (visible ? ` ${styles.crossIcon}` : '')} />
          </div>
        </div>}
      </Container>
    </Navbar>
    <div className={styles.menuAnchor}>
      <div className={styles.menuContent + (visible ? ` ${styles.menuContentVisible}` : '')}>
        <IconList horizontal={true} center={true}>
          <IconItem to='/japanisch-lernen-wie-anfangen/' icon='hints'>Japanisch anfangen</IconItem>
        </IconList>
        <SectionNav className={styles.menuNav} />
      </div>
    </div>
  </div>);
};

export default Header;