import { Link } from 'gatsby'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import MenuLink from '../basic/MenuLink'

import logo from '../logo-white.png'
import swoosh from './footer-dark-swoosh.svg'
import styles from './Footer.module.scss'
import jakob from './jakob-red.jpg'

import iconFacebook from './facebook-white.png'
import iconInstagram from './instagram-white.png'
import iconYoutube from './youtube-white.png'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.footerRed}>
      <Container>
        <div className={styles.containerJakob}>
          <div className={styles.jakob}>
            <div className={styles.hello}>
              Hallo!
            </div>
            <div className={styles.message}>
              <img src={jakob} alt='Jakob'/>
              <div className={styles.messageText}>
                Ich bin Jakob.<br/>
                Seit 2016 veröffentliche ich hier Inhalte zum <Link to='/' rel='home'>Japanisch Lernen</Link>.
                <div className={styles.link}><MenuLink to='/jakob/'>mehr ...</MenuLink></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <div className={styles.darkFooter}>
      <img className={styles.swoosh} src={swoosh} alt=''/>
      <Container>
        <Row>
          <Col md={12} lg={4}>
            <div className={styles.logo}>
              <Link to='/' rel='home'>
                <img src={logo} width={32} height={32} alt='Startseite'/>
                <span>Jakobs<br/>Japanisch</span>
              </Link>
            </div>
          </Col>
          <Col md={12} lg={4}>
          <div className={styles.socials}>
            <a href='https://www.facebook.com/jakobsjapanisch' rel='noopener noreferrer'><img src={iconFacebook} width={32} height={32} alt='Facebook'/></a>
            <a href='https://www.instagram.com/jakobsjapanisch/' rel='noopener noreferrer'><img src={iconInstagram} width={32} height={32} alt='Instagram'/></a>
            <a href='https://www.youtube.com/channel/UCzGx6XoIkkXOYY-exfKPKjw' rel='noopener noreferrer'><img src={iconYoutube} width={32} height={32} alt='Youtube'/></a>
          </div>
          </Col>
          <Col md={12} lg={4}>
            <div className={styles.impressum}>
              <MenuLink to='/impressum/'>Impressum {'&'} Datenschutz</MenuLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Footer;