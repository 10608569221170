import { Link } from 'gatsby'
import React from 'react'

import styles from './MenuLink.module.scss'

export interface IMenuLinkProps {
  to: string
  children: any
  style?: 'normal' | 'secret'
  className?: string
}

const MenuLink = (props: IMenuLinkProps) => {
  let style = props.style === 'secret' ? styles.linkSecret : styles.linkNormal
  if (props.className) {
    style += ' ' + props.className
  }
  if (props.to.startsWith('http')) {
    let rel = 'noreferrer'

    if (props.to.startsWith('http://amzn.to') ||
      props.to.startsWith('http://www.amazon.de') ||
      props.to.startsWith('https://www.paypal')) {
      rel += ' nofollow'
    }

    let affiliate = ''
    if (props.to.startsWith('http://amzn.to') || props.to.startsWith('http://www.amazon.de/gp/product')) {
      affiliate = ' (Link zu Amazon)'
    }

    return <span><a rel={rel} href={props.to} className={style}>{props.children}</a>{affiliate}</span>
  }
  if (props.to.startsWith('/downloads/') || props.to.startsWith('mailto:')) {
    return <a href={props.to} className={style}>{props.children}</a>
  }
  return <Link to={props.to} className={style}>{props.children}</Link>
}

export default MenuLink
