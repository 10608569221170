import React, { ReactNode } from 'react'
import MenuLink from '../../basic/MenuLink'
import styles from './IconList.module.scss'

interface IIconListProps {
  title?: string
  children: any
  horizontal?: boolean
  center?: boolean
}

export default (props: IIconListProps) => {
  return (<div className={styles.iconListDiv}>
    {props.title &&<h2>{props.title}</h2>}
    <div className={props.center?styles.iconListCenter:''}>
      <ul className={`${styles.iconList} ${props.horizontal?'':styles.iconList_vertical}`}>
        {props.children}
      </ul>
    </div>
  </div>)
}

interface IIconItemProps {
  children: ReactNode
  icon: 'amazon' | 'book' | 'coin' | 'writing' | 'kanji' | 'grammar' | 'hints' | 'kanatrainer' | 'kanjitrainer' | 'vocabulary' | 'jlpt'
  to?: string
  details?: string
}

export const IconItem = (props: IIconItemProps) => {
  const text = props.to ? <MenuLink to={props.to}>{props.children}</MenuLink> : props.children
  const details = props.details?<div>{props.details}</div>:null

  return (<li className={styles.iconItem + ' ' + styles[`icon_${props.icon}`]}>{text}{details}</li>)
}
