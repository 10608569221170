import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import SchemaOrg from './SchemaOrg'

interface IHelmetProps {
  title?: string
  frontmatter?: any
  postImage?: string
  isArticle?: boolean
  path?: string
  book?: { title: string, path: string, titleInBook: string }
}

export default (props: IHelmetProps) => {
  const { site } = useStaticQuery(graphql`query SiteMetadataQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        author {
          name
        }
        organization {
          name
          url
          logo
        }
        social {
          twitter
          fbAppID
        }
      }
    }
  }`);
  const meta = site.siteMetadata

  const title = props.frontmatter&&props.frontmatter.title ? `${props.frontmatter.title} | ${meta.title}` : (props.title || meta.title)
  const image = props.postImage ? `${meta.siteUrl}${props.postImage}` : `${meta.siteUrl}${meta.image}`
  const description = props.frontmatter&&props.frontmatter.description ? props.frontmatter.description : meta.description
  const url = props.path ? `${meta.siteUrl}${props.path}` : meta.siteUrl
  const datePublished = props.isArticle ? props.frontmatter.date : false

  return <>
    <Helmet 
      htmlAttributes={{ lang : 'de' }}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png?v=xQ7ge7lazN" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png?v=xQ7ge7lazN" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png?v=xQ7ge7lazN" />
      <link rel="manifest" href="/favicon/site.webmanifest?v=xQ7ge7lazN" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg?v=xQ7ge7lazN" color="#df393f" />
      <link rel="shortcut icon" href="/favicon/favicon.ico?v=xQ7ge7lazN" />
      <meta name="apple-mobile-web-app-title" content="Japanisch" />
      <meta name="application-name" content="Japanisch" />
      <meta name="msapplication-TileColor" content="#ff0000" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml?v=xQ7ge7lazN" />
      <meta name="theme-color" content="#ffffff" />

      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={url} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {props.isArticle ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Facebook tags */}
      <meta property="fb:app_id" content={meta.social.fbAppID} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={meta.social.twitter} />
      {/* use og: <meta name="twitter:title" content={title} />*/}
      {/* use og: <meta name="twitter:description" content={description} />*/}
      {/* use og: <meta name="twitter:image" content={image} />*/}
    </Helmet>
    {<SchemaOrg
      isBlogPost={props.isArticle}
      url={url}
      title={title}
      image={image}
      description={description}
      datePublished={datePublished}
      siteUrl={meta.siteUrl}
      author={meta.author}
      organization={meta.organization}
      defaultTitle={meta.title}
      bookName={props.book&&props.book.title}
      bookUrl={props.book&&`${meta.siteUrl}${props.book.path}`}
      titleInBook={props.book&&props.book.titleInBook}
    />}
</>
}
